.card-body {
  .category-top {
    .location {
      font-weight: bold;
      transform: uppercase;
    }

    .text:not(:empty) + .location::before {
      content: ' - ';
      font-weight: 300;
    }

    .time:before {
      display: inline-block;
      margin: 0 8px;
      content: '—';
    }
  }
}
