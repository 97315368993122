.container .block .searchSections:not(.button) {
  h2.search-section-title {
    color: white;
  }

  .searchSections-background picture {
    height: 100%;

    img {
      height: 100%;
      object-position: center;
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.3);
    }
  }

  .searchContainer {
    margin: 100px 0 165px;
  }
}
