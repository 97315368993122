// TO DO: da rimuovere quando viene sistemato su DCPT
body.cms-ui {
  // remove scroll body when open modal
  &.dimmable.dimmed {
    overflow: hidden;
  }

  &.contenttype-modellopratica {
    .ui.page.modals.dimmer .ui.modal .content {
      overflow-y: hidden;
      padding-top: 0;
    }
  }
  &.contenttype-servizio #page-add .field.field-wrapper-model {
    display: none;
  }
}
