.contenttype-indirizzo {
  #info_point {
    .table {
      .row {
        &:not(:last-child) {
          border-bottom: 1px solid #e9e9e9;
        }
        .col {
          padding: 10px 10px;
          div,
          a {
            padding: 0px;

            p {
              margin: 0px;
            }
          }

          &.col-h {
            background-color: $primary-a0;
          }
        }
      }
    }

    .rich-text p {
      margin-bottom: 0px;
    }
  }
}
