.it-footer {
  a {
    text-decoration: underline;

    &:hover {
      color: transparentize($color: white, $amount: 0.15) !important;
    }
  }

  .it-footer-main {
    background-color: $primary;

    section.border-top {
      border: 0 !important;
    }

    .it-brand-wrapper {
      a {
        display: inline-flex;

        .icon {
          width: 76px;
          height: auto;
          margin-right: 16px;
          margin-bottom: 0;
        }

        h2 {
          font-size: 1.778em;
          margin-bottom: 0;
          font-weight: 600;
          line-height: 1.1;
        }

        h3 {
          color: $header-title-payoff;
          font-weight: 700;
        }
      }
    }

    .social {
      .text-white {
        .icon-white {
          fill: currentcolor !important;
        }
      }
    }

    .row {
      & > div {
        padding: 0 0.5rem;
      }

      h4 {
        display: flex;
        align-items: center;
        padding-bottom: 1rem;
        margin-bottom: 1rem;
        border-bottom: 1px solid #fff;
      }
    }
  }

  .it-footer-small-prints {
    background: $primary;

    .it-footer-small-prints-list {
      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 1rem;
        right: 1rem;
        height: 1px;
        background-color: #fff;
      }
    }
  }

  @media (max-width: map-get($map: $grid-breakpoints, $key: 'sm') - 1px) {
    .link-list-wrapper {
      display: none !important;
    }

    .row > div {
      width: calc(100% - 72px);
    }
  }
}
