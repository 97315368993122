.card-slide-text-template {
  .box {
    .category {
      background-color: $yellow;
      color: #0f206c;
      font-weight: 600;

      &::after {
        border-top-color: $yellow;
      }
    }
  }
}
