//site
$primary-h: 240 !default;
$primary-s: 95 !default;
$primary-b: 15 !default;

$primary: #02024a;
$secondary: #5d6e82;
$tertiary: #5d6e82;
//$tertiary-text: $white;
$buttons-color-override-imola: #117da5;

$header-nci: #636d88;
$header-title-payoff: #40b2dd;
$yellow: #ffd500;

//$header-bg-color: $monet-blue;
//$header-center-bg-color: $header-bg-color;

//$headings-color: $primary;
//$semibold: 600;

$dark-background-title: #fff !default;
$bim-background: #000066;
$casa-piani-background: #cc0000;
$archivio-storico-background: rgb(148, 42, 140);

$subsite-primary: white;
$subsite-primary-text: black;
$subsite-secondary: #000066;
$subsite-secondary-text: white;
$subsite-tertiary: #757575;
$subsite-tertiary-text: white;
$subsite-link-color: #4b71b3;
$subsite-footer-color: #000066;
