/* italia-design style */
// @import "design-comuni-plone-theme/../theme/_site-variables.scss";

/* bootstrap variables */
// @import "bootstrap-italia/src/scss/functions";
// @import "bootstrap-italia/src/scss/utilities/colors_vars";
// @import "bootstrap-italia/src/scss/variables";
// @import "design-comuni-plone-theme/../theme/bootstrap-override/custom_variables";
// @import "bootstrap/scss/functions";

/* site */
// @import "variables";

/* general site */
$primary-hover: color-hover($primary) !default;
$grid-gutter: 24px !default;

/* surveyJs components */
$card-bg-grey: #ebeef0 !default;
$padding-x-panel: 1.5rem !default;
$font-size-title-panel: 2.22222rem !default;

/* override variables - queste variabili sono viste in tutto l'addon anche se non vengono importate nel file del singolo componente */
/* XXX: i nomi delle variabili (almeno alcuni) sono troppo generici per essere messi qui */
:root {
  --font-family: #{$font-family-sans-serif};
  --background-dim: transparent;
  --primary: #{$primary};
  --primary-rgb: #{red($primary)}, #{green($primary)}, #{blue($primary)};
  --primary-hover: #{$primary-hover};
  --primary-light: #{$primary-hover};
  --secondary: #{$secondary};
  --warning: #{$warning};
  --danger: #{$danger};
  --success: #{$success};
  --gray-600: #{$gray-600};
  --grid-gutter: #{$grid-gutter};
  --gray-border-disabled: #{$gray-border-disabled};
  --spacer: #{$spacer};
  --card-bg-grey: #{$card-bg-grey};
  --padding-x-panel: #{$padding-x-panel};
  --font-size-title-panel: #{$font-size-title-panel};
  --link-list-font-size: #{$link-list-font-size};
}

@import 'cms-ui';

/* ICONS */
.icon-small {
  width: 1.11rem !important;
  height: 1.11rem !important;
}

.icon-medium {
  width: 1.33rem !important;
  height: 1.33rem !important;
}

/* TOAST NOTIFICATION */
.notification.bottom-fix {
  z-index: 999;
}
/* Spinner for payments */
.public-ui .payment .progress-spinner {
  &.size-sm {
    height: 24px;
    width: 24px;
  }

  &.progress-spinner-active:not(.progress-spinner-double) {
    border-color: hsl(0, 0%, 68%);
    border-bottom-color: #fff;
  }
}
