.imola-dove {
  .card-title {
    font-size: 1.2rem;
    font-weight: 400;
    display: flex;
    align-items: center;
    .icon {
      height: 1.2rem;
      width: auto;
    }
    a:hover {
      text-decoration: none;
    }
  }

  .involved-in-label {
    color: $secondary;
    span {
      color: $primary;
    }
  }

  .contacts {
    a:hover {
      text-decoration: none;
    }

    p {
      margin-bottom: 0px;
    }
  }

  a.read-more {
    right: 24px;
  }
}
