#page-login,
#unauthorized-agid {
  .view-wrapper {
    .btn {
      width: 320px;
      @media (max-width: #{map-get($grid-breakpoints, sm)}) {
        width: 100%;
      }
    }
  }
}
